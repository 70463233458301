import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "malt"
    }}>{`Malt`}</h1>
    <h2 {...{
      "id": "ebc"
    }}>{`EBC`}</h2>
    <p>{`The easiest way to calculate EBC is use `}<inlineCode parentName="p">{`EBC-total/Weight-total`}</inlineCode>{`
EBC-total: accumulation of weight of malt multiplied by its BEC,
Weight-total : the total weight of the malt used in mashing.( Schüttung )`}</p>
    <h2 {...{
      "id": "types"
    }}>{`Types`}</h2>
    <ul>
      <li parentName="ul">{`Caramel Malt: increase the body and stability of foam, add sweetness. Rarely should the total caramel malt exceed 10% of the grain bill, and 5% is probably a better target. more types see `}<a parentName="li" {...{
          "href": "http://beersmith.com/blog/2014/06/26/caramel-and-crystal-malt-in-beer-brewing/"
        }}>{`here`}</a></li>
    </ul>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Malt name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`EBC`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Aroma`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`142-158`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Chocolate`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`800-1000`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Pale Ale`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`5.5-7.5`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Caramel Hell`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`20-30`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Caramel Red`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`40-60`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      